import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const AtglanceSlider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              slidesPerView={3}
              speed={1000}
              spaceBetween={15}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  allowSlideNext: props.disable ? false : true,
                  allowSlidePrev: props.disable ? false : true,
                },
                768: {
                  slidesPerView: 2,
                  allowSlideNext: true,
                  allowSlidePrev: true,
                },
                0: {
                  slidesPerView: 1,
                  allowSlideNext: true,
                  allowSlidePrev: true,
                },
              }}
              modules={[Autoplay]}
            >
              {props.slider3.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="overflow-hidden">
                      <Fade bottom cascade>
                        <div className=" mt-20 text-dusk">
                          <div className="text-center ">
                            <div
                              className={
                                props.mint ? "text-mint text-5xl" : "text-5xl"
                              }
                            >
                              {item.topic}
                            </div>
                            <div
                              className={
                                props.mint
                                  ? "text-mint text-2xl  mt-2"
                                  : "text-2xl mt-2"
                              }
                            >
                              {item.desc}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default AtglanceSlider
