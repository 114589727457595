// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"

import AtglanceSlider from "../components/common/at-glance-slider"
import ToolkitSlider from "../components/toolkit/toolkit-slider"

const Toolkit = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),

    mint_texture: convertToBgImage(getImage(data.mint_texture)),

    //home page images

    banner: convertToBgImage(getImage(data.home1)),
    data: convertToBgImage(getImage(data.home2)),
    feedback: convertToBgImage(getImage(data.home3)),
    flex_battery_img: convertToBgImage(getImage(data.home4)),
    sensing_img: convertToBgImage(getImage(data.home5)),
    thermal_img: convertToBgImage(getImage(data.home6)),
    touch_img: convertToBgImage(getImage(data.home7)),
    visibility_img: convertToBgImage(getImage(data.home8)),
    circuitry_img: convertToBgImage(getImage(data.home9)),
    feedback_img: convertToBgImage(getImage(data.feedback)),

    feedback: getImage(data.feedback),

    //platform application images

    haptic_img: convertToBgImage(getImage(data.plat_app1)),
    motion_bra_img: convertToBgImage(getImage(data.plat_app2)),
    sense_pant_img: convertToBgImage(getImage(data.plat_app3)),
    Bannerimagetoolkit: convertToBgImage(getImage(data.Bannerimagetoolkit)),
    KYF_6515: convertToBgImage(getImage(data.KYF_6515)),
    KYF_6410: convertToBgImage(getImage(data.KYF_6410)),
    KYF_6421: convertToBgImage(getImage(data.KYF_6421)),
    Mianpgaeimage: convertToBgImage(getImage(data.Mianpgaeimage)),
    KYF_6469: convertToBgImage(getImage(data.KYF_6469)),
    KYF_6482: convertToBgImage(getImage(data.KYF_6482)),
    KYF_6497: convertToBgImage(getImage(data.KYF_6497)),
    MainpageThermal: convertToBgImage(getImage(data.MainpageThermal)),
    KYF_6511: convertToBgImage(getImage(data.KYF_6511)),
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),

    Applications: getImage(data.Applications),
    Integration: getImage(data.Integration),
    Streamlined: getImage(data.Streamlined),
    Technology: getImage(data.Technology),

    //our collection image

    Data_Transmission_our: convertToBgImage(
      getImage(data.Data_Transmission_our)
    ),
    sensing_our: convertToBgImage(getImage(data.sensing_our)),
    Sustanability_our: convertToBgImage(getImage(data.Sustanability_our)),
    Thermal_Regulation_our: convertToBgImage(
      getImage(data.Thermal_Regulation_our)
    ),
    wristable_our: convertToBgImage(getImage(data.wristable_our)),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const slider3 = [
    {
      topic: " Creation",
      desc: " Explore new artforms that molded using fabric-based technologies",
    },

    {
      topic: " Development  ",
      desc: " Cost effective rapid prototyping and testing of products for your next seasons product line",
    },

    {
      topic: " Research",
      desc: "Explore textile concepts to understand potential areas of research",
    },
  ]

  const slider4 = [
    {
      topic: "Test Application",
      image: images.Applications,
      desc: "Rapid testing of concepts with a low initial investment",
    },
    {
      topic: "Streamlined",
      image: images.Streamlined,
      desc: "Self-contained toolboxes can be assembled with only package content.  No additional tools required beyond basic tooling",
    },
    {
      topic: "Technology",
      image: images.Technology,
      desc: "Explore our technology platforms firsthand.  Build prototypes quickly that work with your existing architecture",
    },
    {
      topic: "Integration",
      image: images.Integration,
      desc: "All prototypes are designed to interface together, creating clean solution concepts ",
    },
  ]
  const [active, setActive] = useState(0)

  const platform = [
    {
      title: "Textile Circuitry",
      sub_title: "Textile Circuitry Kit",
      content:
        "Explore moving beyond traditional cabling; replace rigid copper core cabling with fabric connectors.  This box contains a variety of cables, including high speed data cables that can transfer 4K signals up to 5Gbps.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.Data_Transmission_our,
      link: "/shop/textile-circuitry-development-kit",
    },
    {
      title: "Thermo Regulation",
      sub_title: "Thermo Regulation Toolkit",
      content:
        "Our heating panels have now been used for a range of commercial application.  Explore different heating panel technologies including flat knit panels, circular knit cut panels, and embroider wire panels.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.Thermal_Regulation_our,
      link: "/shop/thermo-regulation-development-kit",
    },
    {
      title: "Sensing",
      sub_title: "Sensing Toolkit",
      content:
        "Fabric based sensors all support materials, for you to explore data acquisition across a textiles interface.  Sensors can be treated no differently to traditional electronic sensor, and can be configured with any micro controller",
      image: "../../images/brand/symbol-mint.png",
      cover: images.sensing_our,
      link: "/shop/sensing-development-kit",
    },
    {
      title: "Materials",
      sub_title: "Materials Toolkit",
      content:
        "We looked at all our best-selling writable technologies and narrowed down textile straps that can be integrated with any wristable.  All samples model final look, feel and profile characteristics of manufactured sample.  However, can be customized through a development process. ",
      image: "../../images/brand/symbol-mint.png",
      cover: images.wristable_our,
      link: "/shop/materials-development-kit",
    },
    {
      title: "Sustainability",
      sub_title: "Sustainability Toolkit",
      content:
        "Our mission as a company has always been to design responsible product.  Through a multiphase process we evaluate yarns and textiles that can leveraged to create sustainable alternatives to traditional materials.  Within this toolbox we synthesis this exploration by providing range of sustainable materials that can be used in your next project.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.Sustanability_our,
      link: "/shop/sustainable-development-kit",
    },
  ]

  return (
    <Layout>
      <Seo title="Integrated Technologies" />
      <section>
        <BackgroundImage
          {...images.Bannerimagetoolkit}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center ">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 max-w-2xl text-mint">
                    We make circuits for
                    <div>soft goods</div>
                  </div>
                  <div className=" sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Redefining what’s possible through
                    <div>power and integration.</div>
                  </div>
                  <a href="#explore">
                    <div className="flex flex-wrap md:justify-start justify-center ">
                      <div className="bg-mint px-5 py-3 text-black uppercase mt-8 cursor-pointer">
                        explore kits
                      </div>
                    </div>
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-mint">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-white mb-4 uppercase text-center ">
                    DEVELOPMENT AND PROTOTYPE TOOLKITS
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk max-w-md mx-auto">
                    Bringing electric circuitry to everyday fabrics
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Over the years our team has been engineering the textile
                    circuitry in your favorite wearable technology and
                    softgoods. Today we launch tools boxes that brings that very
                    same technology into your lab, research facility and home.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-dusk md:py-56 py-24 text-chalk px-5">
                <div className=" text-2xl sm:text-4xl mb-4 px-1 text- max-w-xl mx-auto text-mint">
                  Fueling the next generation of solutions
                </div>
                <div className="text-xl mt-2  max-w-xl mx-auto">
                  When our technology teams came together to design these
                  toolboxes, we wanted to understand how we could bring gamut of
                  technologies into a toolbox that would be useful for all
                  creators. After much consideration we launched five toolboxes
                  that are tailored for different needs, across different
                  industries. All boxes are stand alone and can be explored
                  using everyday tools. Purchase one of our prototyping packs to
                  deep dive into our technology.
                </div>
              </div>
            </Fade>
          </div>
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.Mianpgaeimage}
              className="h-full bg-cover lg:py-0 py-40"
            ></BackgroundImage>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-mint">
          <div className="container mx-auto py-14">
            <Fade top>
              <div>
                <div className="text-dusk text-xl sm:text-left text-center ">
                  DESIGNED FOR:
                </div>
              </div>
            </Fade>

            <AtglanceSlider slider3={slider3} disable />
          </div>
        </div>
      </section>

      <section>
        <div className="py-20 bg-dusk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-xl font-light text-center  mb-4 text-chalk">
                OUR COLLECTION OF
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="text-4xl font-light text-center mx-auto text-chalk max-w-4xl leading-snug"
                id="explore"
              >
                Development Kits
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex md:flex-row flex-col items-center justify-center lg:gap-10 sm:gap-5">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-mint sm:text-xl text-base sm:text-left text-center px-4 duration-200"
                              : "font-firs-semibold cursor-pointer text-chalk sm:text-xl text-base sm:text-left text-center px-4 duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[active].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                      <div className="text-3xl font-light text-mint">
                        {platform[active].sub_title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content2}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content3}
                      </div>
                      <Link to={platform[active].link}>
                        <div className="flex flex-wrap md:justify-start justify-center ">
                          <div className="bg-mint px-5 py-3 text-black uppercase mt-8 cursor-pointer">
                            Buy now
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.KYF_6482}
              className="h-full bg-cover lg:py-0 py-40"
            ></BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-salmon md:py-56 py-24 text-chalk px-5">
                <div className=" text-2xl sm:text-4xl md:text-left text-center mb-4 px-1 text- max-w-xl mx-auto text-dusk">
                  Where to Start?
                </div>
                <div className="text-xl mt-2 text-chalk max-w-xl mx-auto  md:text-left text-center">
                  As you begin your prototyping you may have questions.
                  Designing a new heater application can be an intimidating
                  process, but it doesn’t have to be. Get started by reading our
                  instruction manual. This should introduce you to the
                  technology in focus and give you and understanding how the
                  component can be made to interface with other devices. Our
                  spec sheets should get you started on mapping out the
                  technical parameters for your project. Did we miss anything?
                  Connect with us, and our technical teams will help resolve any
                  open queries
                  <Link to="/contact">
                    <div className="flex flex-wrap md:justify-start justify-center ">
                      <div className="bg-dusk px-5 py-3 text-chalk  uppercase mt-8 cursor-pointer">
                        connect with us
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto">
          <div className=" text-2xl sm:text-4xl  px-1  max-w-xl  text-dusk pb-14 pt-20">
            Why use Kits?
          </div>

          <ToolkitSlider slider={slider4} />
          <div className="flex justify-center w-full mb-20">
            <div className="my-pagination-div flex justify-center w-5"></div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk overflow-hidden">
          <div className=" pt-24">
            <Fade bottom cascade>
              <div className="text-white md:px-0 px-10">
                <div className="text-center text-2xl font-firs-thin text-mint mb-4">
                  DESIGNED FOR VARIED NEEDS AND USES
                </div>
                <div className="text-center text-4xl lg:text-6xl mb-4 text-mint">
                  Across Industries
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Our technology toolboxes are design with all industries in
                  mind. While each toolbox across multiple applications, we
                  recommend you start by establishing a base line for what type
                  of application you wish to explore. Here are a few examples of
                  how our technology has been deployed in the past
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col">
                <div className="lg-full g:w-1/2">
                  <Fade right>
                    <div className="w-full block lg:hidden">
                      <BackgroundImage
                        {...images.KYF_6482}
                        className="py-44 sm:py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className=" lg:pr-10 sm:text-left text-center">
                      <div className="uppercase text-mint">
                        TEXTILE CIRCUITRY KIT
                      </div>
                      <div className="text-4xl  text-mint font-light mb-3 leading-tight">
                        Immersive X
                      </div>
                      <div className="text-base max-w-xl text-chalk">
                        In a VR application where large volumes of data needed
                        to be transferred across the body, safely and reliable,
                        our high speed data cable was a great match. A thin
                        elastic band can connect a high-resolution camera place
                        on the forehead with a processing module using placed on
                        the back of the head.
                      </div>
                      <Link to="/shop/textile-circuitry-development-kit">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          buy Now
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.KYF_6482}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.MainpageThermal}
                        className="h-full bg-cover py-40 lg:py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className=" lg:pr-10 sm:text-left text-center">
                      <div className="uppercase text-mint">
                        THERMO REGULATION KIT
                      </div>
                      <div className="text-4xl text-mint font-light mb-3 leading-tight">
                        Active Technologies
                      </div>
                      <div className="text-base max-w-xl text-chalk">
                        Features a collection of our latest heating panels and
                        electronics used in smart apparel and soft goods. Our
                        offering includes flat knit panels, circular knit cut
                        panels, embroidered wire panels and printed heating
                        panels. Flat knitted panels typically used in fully
                        knitted form factors, are useful when creating zero
                        waste, zero seam and high value apparel.
                      </div>
                      <Link to="/shop/thermo-regulation-development-kit">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          buy Now
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 block lg:hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.KYF_6410}
                        className="h-full bg-cover lg:py-0 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className=" lg:pr-10 sm:text-left text-center">
                      <div className="uppercase text-mint">SENSING KIT</div>
                      <div className="text-4xl text-mint font-light mb-3 leading-tight">
                        Connected Clothing
                      </div>
                      <div className="text-base max-w-xl text-chalk">
                        Fabric based sensors all support materials, for you to
                        explore data acquisition across a range of connected
                        apparel form factors. Sensors can be treated no
                        differently to traditional electronic sensor, and can be
                        configured with any micro controller.
                      </div>
                      <Link to="/shop/sensing-development-kit">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          buy Now{" "}
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.KYF_6410}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 ">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.Mianpgaeimage}
                        className="py-44 sm:py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className=" lg:pr-10 sm:text-left text-center">
                      <div className="uppercase text-mint">MATERIALS KIT</div>
                      <div className="text-mint text-4xl font-light mb-3 leading-tight">
                        Connected Devices
                      </div>
                      <div className="text-chalk text-base max-w-xl">
                        With our fabric strap business continuously growing,
                        this toolkit allows you to get your fingers on a
                        carefully curated selection of engineered knit swatches,
                        narrow width straps and knitted straps. These straps
                        provide a granular look into our capabilities
                        surrounding comfort, design, stretch and feel.
                      </div>
                      <Link to="/shop/materials-development-kit">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          buy Now
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="w-full lg:w-1/2 block lg:hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.KYF_6421}
                        className="h-full bg-cover lg:py-0 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className=" lg:pr-10 sm:text-left text-center">
                      <div className="uppercase text-mint">
                        SUSTAINABILITY KIT
                      </div>
                      <div className="text-4xl text-mint font-light mb-3 leading-tight">
                        Responsible Making
                      </div>
                      <div className="text-chalk text-base max-w-xl">
                        Our mission as a company has always been to design and
                        build environmentally responsible products. Through a
                        multiphase process we evaluate yarns and textiles that
                        can be leveraged to create sustainable alternatives to
                        traditional materials. This toolkit includes recycled
                        polyester, seacell, tea dye bionic nylon and lot more
                        types of sustainable fabrics.
                      </div>

                      <Link to="/shop/sustainable-development-kit">
                        <button className="uppercase px-4 py-3 border border-mint rounded-full text-mint mt-6 hover:bg-mint/20 cursor-pointer duration-200">
                          buy Now{" "}
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-full lg:w-1/2 hidden lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.KYF_6421}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-chalk py-56 text-dusk px-5">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  Interested in partnering with Us?
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  Need more information or interested in selling Softmatter
                  Packs & Parts in your store? Please get in touch for more
                  information.
                </div>

                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-dusk  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ToolkitImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    home1: file(relativePath: { eq: "integrated-technologies/banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home2: file(relativePath: { eq: "integrated-technologies/Data.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home3: file(relativePath: { eq: "integrated-technologies/feedback.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home4: file(
      relativePath: { eq: "integrated-technologies/Flexible_battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home5: file(relativePath: { eq: "integrated-technologies/Sensing.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home6: file(relativePath: { eq: "integrated-technologies/Thermal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home7: file(relativePath: { eq: "integrated-technologies/Touch.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home8: file(
      relativePath: { eq: "integrated-technologies/Visibility.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    home9: file(
      relativePath: { eq: "integrated-technologies/Textile_Circ.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    feedback: file(
      relativePath: { eq: "integrated-technologies/feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app1: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Haptic_Pants.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app2: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Motion_sensing_bra.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    plat_app3: file(
      relativePath: {
        eq: "integrated-technologies/Platform-Applications/Sensing_Pants.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6410: file(relativePath: { eq: "toolkit/KYF_6410.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Mianpgaeimage: file(
      relativePath: { eq: "toolkit/landingupdate/Mianpgaeimage.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6421: file(relativePath: { eq: "toolkit/KYF_6421.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Bannerimagetoolkit: file(
      relativePath: { eq: "toolkit/landingupdate/Bannerimagetoolkit.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6469: file(relativePath: { eq: "toolkit/KYF_6469.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6482: file(relativePath: { eq: "toolkit/KYF_6482.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6497: file(relativePath: { eq: "toolkit/KYF_6497.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    MainpageThermal: file(
      relativePath: { eq: "toolkit/landingupdate/MainpageThermal.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6511: file(relativePath: { eq: "toolkit/KYF_6511.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    KYF_6515: file(relativePath: { eq: "toolkit/KYF_6515.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Applications: file(
      relativePath: { eq: "toolkit/carousel/Applications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Streamlined: file(
      relativePath: { eq: "toolkit/carousel/Streamlined.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Integration: file(
      relativePath: { eq: "toolkit/carousel/Integration.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Technology: file(relativePath: { eq: "toolkit/carousel/Technology.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Data_Transmission_our: file(
      relativePath: { eq: "toolkit/collection/Data_Transmission .png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    wristable_our: file(
      relativePath: { eq: "toolkit/collection/wristable.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Sustanability_our: file(
      relativePath: { eq: "toolkit/collection/Sustanability.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Thermal_Regulation_our: file(
      relativePath: { eq: "toolkit/collection/Thermal_Regulation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    sensing_our: file(relativePath: { eq: "toolkit/collection/sensing.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Toolkit
