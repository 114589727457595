import React from "react"
import Fade from "react-reveal/Fade"
// import Swiper core and required modules
import { Autoplay, Pagination } from "swiper"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const ToolkitSlider = props => {
  return (
    <>
      <div className="">
        <>
          <Fade bottom cascade>
            <Swiper
              autoHeight={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              speed={1000}
              spaceBetween={15}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              pagination={{
                el: ".my-pagination-div",
                clickable: true,
              }}
              modules={[Pagination]}
            >
              {props.slider.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div>
                      <div className="flex md:flex-row flex-col items-center bg-dusk mb-5 ">
                        <div className="md:w-1/2 w-full">
                          <GatsbyImage image={item.image} className="w-full" />
                        </div>
                        <div className="md:w-1/2 w-full flex justify-center">
                          <div className="px-8 md:py-0 py-20">
                            <div className="text-3xl text-salmon ">
                              {item.topic}
                            </div>
                            <div className="text-chalk">{item.desc}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Fade>
        </>
      </div>
    </>
  )
}

export default ToolkitSlider
